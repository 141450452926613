<script>
export default {
  props: ['task'],
}
</script>
<template>
  <div
    class="brand-black-2 brand-shadow-1 rounded-8"
    style="padding: 1rem 1rem 0.5rem; min-width: 171px"
  >
    <h6 class="brand-text-h6">Order #{{ task.orderId }}</h6>
    <p
      class="brand-text-caption brand-grey--text"
      style="margin: -3px 0 0.5rem"
    >
      {{ task.createdBy || '–' }}
    </p>

    <div
      class="brand-text-caption brand-grey--text"
      style="margin-bottom: 2px; display: flex; justify-content: space-between"
    >
      <p>Progress</p>
      <p>{{ task.progress }}%</p>
    </div>
    <div
      class="brand-grey-2"
      style="width: 100%; height: 4px; position: relative; border-radius: 2px"
    >
      <div
        class="brand-yellow"
        :style="{
          position: 'absolute',
          width: `${task.progress}%`,
          height: '4px',
          'border-radius': '2px',
        }"
      ></div>
    </div>
    <h3
      class="brand-text-caption brand-grey--text"
      style="float: right; margin: 6px 0 0"
    >
      {{ task.daysLeft != void 0 ? task.daysLeft : '–' }} day{{
        task.daysLeft !== 1 ? 's' : ''
      }}
      left
    </h3>
  </div>
</template>
