import { createServer, Model, Factory } from 'miragejs'
import faker from 'faker'
import Types from '@/common/types.js'

export function makeServer({ environment = 'development' } = {}) {
  let server = createServer({
    environment,

    models: {
      task: Model,
    },

    factories: {
      task: Factory.extend({
        orderId: () => faker.datatype.uuid().slice(0, 8).toUpperCase(),
        createdBy: () => `${faker.name.firstName()} ${faker.name.lastName()}`,
        status: () => faker.random.arrayElement(Types.Task.allStatus),
        progress() {
          return this.status === Types.Task.completed
            ? 100
            : [Types.Task.in_progress, Types.Task.started].includes(this.status)
            ? faker.datatype.number({ min: 1, max: 99 })
            : 0
        },
        daysLeft() {
          return [Types.Task.in_progress, Types.Task.started].includes(
            this.status
          )
            ? faker.datatype.number({ min: 1, max: 4 })
            : 0
        },
      }),
    },

    seeds(server) {
      server.createList('task', faker.datatype.number({ min: 20, max: 40 }))
    },

    routes() {
      this.timing = 250

      // Taks

      this.get('/task/list', (schema, request) => {
        // console.log(faker.random)
        // console.log(schema.tasks.all())
        const tasks = request.queryParams.status
          ? schema.tasks.where(request.queryParams).models
          : schema.tasks.all().models

        return ok(tasks)
      })

      // Statistics

      this.get('/statistics/general', (schema) => {
        return ok({
          primary: {
            'Total projects': schema.tasks.all().length,
            Completed: schema.tasks.where({ status: Types.Task.completed })
              .length,
            'In Progress': schema.tasks.where({
              status: Types.Task.in_progress,
            }).length,
            Delayed: schema.tasks.where({ status: Types.Task.delayed }).length,
          },
          secondary: {
            'Upcoming Tasks': schema.tasks.where({
              status: Types.Task.upcoming,
            }).length,
          },
        })
      })

      this.get('/statistics/chart/:type', (schema, request) => {
        const types = {
          [Types.ChartStat.day]: {
            labels: ['Oct 11', 'Oct 12', 'Oct 13', 'Oct 14', 'Oct 15'],
            minmax: { min: 300, max: 1100 },
          },
          [Types.ChartStat.week]: {
            labels: ['02/09', '03/09', '04/09', '01/10', '02/10'],
            minmax: { min: 2000, max: 8000 },
          },
          [Types.ChartStat.month]: {
            labels: ['May', 'June', 'July', 'Aug', 'Sept', 'Oct'],
            minmax: { min: 8000, max: 15000 },
          },
          [Types.ChartStat.year]: {
            labels: ['2016', '2017', '2018', '2019', '2020', '2021'],
            minmax: { min: 80000, max: 150000 },
          },
        }

        const labels = types[request.params.type].labels

        return ok({
          labels,
          series: labels.map(() =>
            faker.datatype.number(types[request.params.type].minmax)
          ),
        })
      })
    },
  })

  return server
}

const ok = (data) => ({ data })
