<script>
export default {
  props: ['icon'],
}
</script>
<template>
  <div
    class="x-btn brand-black-2 brand-shadow-2 rounded-4"
    style="display: flex; border: 1px solid #1c232a"
  >
    <button style="flex: 1">
      <img :src="icon" alt="Icon" width="18" height="18" />
    </button>
    <button
      style="width: 16px; padding: 4px 0 4px; border-left: 1px solid #1c232a"
    >
      <img
        src="/statics/icons/arrow-down.png"
        alt="Icon"
        width="10"
        height="10"
      />
    </button>
  </div>
</template>
<style>
.x-btn {
  min-width: 53px;
}

.x-btn button {
  min-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
