import { createApp } from 'vue'
import VueApexCharts from 'vue3-apexcharts'
import { makeServer } from '@/server'
import App from './app.vue'

import './assets/styles/preflight.css'
import './assets/styles/fonts.css'
import './assets/styles/styles.css'
import './assets/styles/utils.css'

makeServer()

createApp(App).use(VueApexCharts).mount('#app')
