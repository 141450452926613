<script>
export default {
  props: {
    modelValue: [String, Object],
    items: Array,
    lg: Boolean,
  },
}
</script>
<template>
  <ul class="x-tabs">
    <li
      v-for="item in items"
      :key="item.label"
      :class="[
        'cursor-pointer',
        lg ? 'brand-text-h4' : 'brand-text-h6',
        { 'brand-yellow--text x-tabs--selected': item.value === modelValue },
      ]"
      @click="$emit('update:modelValue', item.value)"
    >
      {{ item.label }}
    </li>
  </ul>
</template>
<style>
.x-tabs {
  display: flex;
  justify-content: space-between;
}

.x-tabs li {
  background-image: linear-gradient(
    transparent calc(100% - 2px),
    currentColor 2px
  );
  background-repeat: no-repeat;
  background-size: 0 100%;
  position: relative;
  text-decoration: none;
  transition: background-size 0.2s, color 0.2s;
}

.x-tabs li.x-tabs--selected {
  background-size: 85% 100%;
}
</style>
