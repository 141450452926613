<script>
import Types from '@/common/types.js'
import XTabs from '@/components/x-tabs.vue'
import XTaskCard from '@/components/x-task-card.vue'
import XBtn from '@/components/x-btn.vue'

export default {
  data() {
    return {
      status: null,
      allStatus: [
        { label: 'All', value: null },
        ...[
          Types.Task.in_progress,
          Types.Task.started,
          Types.Task.completed,
        ].map((value) => ({
          label: Types.Task.labelStatus[value],
          value,
        })),
      ],
      tasksLoading: false,
      tasks: [{ _loading: true }, { _loading: true }],
      generalStats: {
        primary: {
          'Total projects': '–',
          Completed: '–',
          'In Progress': '–',
          Delayed: '–',
        },
        secondary: { 'Upcoming Tasks': '–' },
      },
      chartType: Types.ChartStat.month,
      allTypes: Types.ChartStat.allTypes.map((value) => ({
        label: Types.ChartStat.labelTypes[value],
        value,
      })),
      currentTotal: null,
      series: [
        {
          name: 'Statistics',
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          height: 180,
          width: '100%',
          type: 'area',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          events: {
            markerClick: (event, chartContext, { dataPointIndex }) => {
              this.currentTotal = this.series[0].data[dataPointIndex]
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
          width: 2,
        },
        yaxis: {
          show: false,
          labels: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
        xaxis: {
          // type: 'datetime',
          categories: [],
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            rotate: -45,
            style: {
              fontFamily: 'Avenir, sans-serif',
              fontWeight: 900,
              fontSize: '16px',
            },
          },
        },
        tooltip: {
          theme: 'dark',
          x: {
            show: true,
          },
        },
        grid: {
          show: false,
        },
        fill: {
          // opacity: 0.1,

          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'vertical',
            inverseColors: false,
            shadeIntensity: 0,
            opacityFrom: 0.5,
            opacityTo: 0,
          },

          // type: 'gradient',
          // gradient: {
          //   shadeIntensity: 1,
          //   inverseColors: false,
          //   opacityFrom: 0.45,
          //   opacityTo: 0.05,
          //   stops: [20, 100, 100, 100],
          // },
        },
        colors: ['#FFBE00'],
      },
      Types,
    }
  },
  created() {
    this.getGeneralStats()
  },
  watch: {
    status: { handler: 'getTasks', immediate: true },
    chartType: { handler: 'getChartStats', immediate: true },
  },
  methods: {
    getTasks() {
      this.tasksLoading = true
      fetch(`/task/list?status=${this.status || ''}`)
        .then((res) => res.json())
        .then((resData) => {
          this.tasks = resData.data
        })
        .finally(() => (this.tasksLoading = false))
    },
    getGeneralStats() {
      fetch('/statistics/general')
        .then((res) => res.json())
        .then((resData) => {
          this.generalStats = resData.data
        })
    },
    getChartStats() {
      this.currentTotal = null

      fetch(`/statistics/chart/${this.chartType}`)
        .then((res) => res.json())
        .then((resData) => {
          this.series = [
            {
              name: 'Statistics',
              data: resData.data.series,
            },
          ]
          this.chartOptions = {
            ...this.chartOptions,
            xaxis: {
              ...this.chartOptions.xaxis,
              categories: resData.data.labels,
            },
          }
        })
    },
  },
  components: {
    XTabs,
    XTaskCard,
    XBtn,
  },
}
</script>
<template>
  <main class="container" style="padding: 2rem 0">
    <header>
      <button>
        <img src="/statics/icons/back.png" alt="Icon" width="24" height="24" />
      </button>
      <h4 class="brand-text-h4">Tasks</h4>
      <button>
        <img
          src="/statics/icons/noun_notification.png"
          alt="Icon"
          width="24"
          height="24"
        />
      </button>
    </header>

    <button
      class="brand-text-h5 brand-yellow--text"
      style="display: block; margin: 0 0 1.5rem auto"
    >
      CREATE +
    </button>

    <x-tabs
      v-model="status"
      :items="allStatus"
      style="margin-bottom: 1rem"
    ></x-tabs>

    <h6 class="brand-text-h6" style="margin-bottom: 0.5rem">
      Upcoming Tasks
      <span class="brand-grey--text" style="margin-left: 4px; font-weight: 300">
        {{ generalStats.secondary['Upcoming Tasks'] }}
      </span>
    </h6>

    <div
      class="tasks"
      :style="{ 'margin-bottom': '1rem', opacity: tasksLoading ? '0.6' : '1' }"
    >
      <x-task-card v-for="task in tasks" :key="task.id" :task="task" />
    </div>

    <div
      style="
        margin-bottom: 0.5rem;
        display: flex;
        justify-content: space-between;
      "
    >
      <h6 class="brand-text-h6">Statistics</h6>
      <x-btn icon="/statics/icons/download.png"></x-btn>
    </div>
    <div
      class="statistics brand-black-3 brand-shadow-1 rounded-8"
      style="margin-bottom: 2rem"
    >
      <div
        v-for="(stat, statName) in generalStats.primary"
        :key="statName"
        class="brand-black-2"
        style="padding: 1rem; display: flex; align-items: center"
      >
        <div>
          <img
            :src="`/statics/statistics/${statName}.png`"
            alt="Icon"
            width="24"
            height="24"
          />
        </div>
        <div style="margin-left: 12px; display: flex; flex-direction: column">
          <span class="brand-text-h4">{{ stat }}</span>
          <span class="brand-grey--text" style="font-size: 14px">{{
            statName
          }}</span>
        </div>
      </div>
    </div>

    <div
      class="brand-black-2 brand-shadow-1 rounded-8"
      style="padding: 1.5rem 0 0; margin-bottom: 1.5rem"
    >
      <x-tabs
        v-model="chartType"
        :items="allTypes"
        style="padding: 0 1.5rem"
        lg
      ></x-tabs>

      <h1 class="brand-text-h1 text-center" style="margin: 1rem 0 0">
        <span style="margin-right: 4px; font-size: 22px; vertical-align: super">
          $</span
        >{{
          (currentTotal &&
            currentTotal.toLocaleString('en-US', {
              maximumFractionDigits: 1,
            })) ||
          '0'
        }}
      </h1>

      <apexchart
        type="area"
        height="180"
        width="100%"
        :options="chartOptions"
        :series="series"
        class="apexcharts--no-tooltip brand-apexcharts-1"
      ></apexchart>
    </div>
  </main>
</template>
<style>
.container {
  margin: 0 32px;
}

header {
  padding: 2rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tasks {
  position: relative;
  width: calc(100% + 32px);
  display: flex;
  overflow-x: auto;
  gap: 0.9rem;
  padding-bottom: 19px;
  margin-bottom: -19px;
  padding-left: 6px;
  margin-left: -6px;
}

.statistics {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1px;
  overflow: hidden;
}
</style>
