export default {
  Task: {
    in_progress: 'IN_PROGRESS',
    started: 'STARTED',
    completed: 'COMPLETED',
    upcoming: 'UPCOMING',
    delayed: 'DELAYED',
    allStatus: ['IN_PROGRESS', 'STARTED', 'COMPLETED', 'UPCOMING', 'DELAYED'],
    labelStatus: {
      IN_PROGRESS: 'In Progress',
      STARTED: 'Started',
      COMPLETED: 'Completed',
      UPCOMING: 'Upcoming',
      DELAYED: 'Delayed',
    },
  },
  ChartStat: {
    day: 'DAY',
    week: 'WEEK',
    month: 'MONTH',
    year: 'YEAR',
    allTypes: ['DAY', 'WEEK', 'MONTH', 'YEAR'],
    labelTypes: {
      DAY: 'Day',
      WEEK: 'Week',
      MONTH: 'Month',
      YEAR: 'Year',
    },
  },
}
